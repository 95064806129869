import FeedWrapper from '../Components/FeedContent/FeedWrapper/FeedWrapper'

const Feed = () => {
  return (
    <div>
      <FeedWrapper />
    </div>
  )
}

export default Feed
