import { getAuth } from 'firebase/auth'
import {useSelector} from 'react-redux'
import {Redirect} from 'react-router-dom'
import DashboardWrapper from '../Components/Dashboard/DashboardWrapper'

const Dashboard = () => {
  const {userAddress} = useSelector((state: any) => state.profile)

  if (!getAuth().currentUser) {
    return <Redirect to='/' />
  }
  return (
    <div>
      <DashboardWrapper />
    </div>
  )
}

export default Dashboard
