export const AppCollections = {
	USERS: 'users',
	MEMES: 'memes',
	NONCE: 'nonce',
	LIKES: 'likes',
	COMMENTS: 'comments',
	REWARDS: 'rewards',
}
export enum RewardTypes {
	LIKES = 'likes',
	POST_LIKES = 'post_likes',
	COMMENTS = 'comments',
}
