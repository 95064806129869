import DefaultModal from '../DefaultModal/DefaultModal'
import {useEffect, useState} from 'react'

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  InstapaperIcon,
  InstapaperShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'
import useClipboard from 'react-use-clipboard'
import {FiCopy} from 'react-icons/fi'

const ShareModal = ({show, handleClose, memID}) => {
  const [loc, setLoc] = useState('')

  useEffect(() => {
    setLoc(`https://${window.location.hostname}/meme/${memID}`)
  }, [])

  const [isCopied, setCopied] = useClipboard(loc, {
    successDuration: 2000,
  })

  return (
    <DefaultModal
      title='Share   Meme'
      show={show}
      handleClose={handleClose}
      type='fail'
    >
      <div className='success__body'>
        <div className='social_share_wrapper my-5 d-flex align-items-center justify-content-between'>
          <EmailShareButton url={loc}>
            <EmailIcon size={32} round={true} />
          </EmailShareButton>
          <FacebookShareButton url={loc}>
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
          <TwitterShareButton url={loc}>
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>
          <InstapaperShareButton url={loc}>
            <InstapaperIcon size={32} round={true} />
          </InstapaperShareButton>
          <LinkedinShareButton url={loc}>
            <LinkedinIcon size={32} round={true} />
          </LinkedinShareButton>
          <TelegramShareButton url={loc}>
            <TelegramIcon size={32} round={true} />
          </TelegramShareButton>
          <WhatsappShareButton url={loc}>
            <WhatsappIcon size={32} round={true} />
          </WhatsappShareButton>
        </div>
        <div className='user__id'>
          <p onClick={setCopied} className='txt__gray id'>
            {loc}
            <span>
              <FiCopy />
            </span>
          </p>
          <div className='toolt'>{isCopied ? 'Copied' : 'Copy'}</div>
        </div>
        <div>
          <button
            onClick={setCopied}
            className='btn_brand w-100 mb-3 btn_outlined'
          >
            {isCopied ? 'Copied' : 'Copy to Clipboard'}
          </button>
        </div>
      </div>
    </DefaultModal>
  )
}

export default ShareModal
