
const DashboardBanner = () => {
  return (
    <>
      <div className='feedsBAnner scsc'>
        <div className='create_Post'>
          <div className='section_info'>
            <h1 className='section_heading'>
              <span>My</span> Portfolio
            </h1>
          </div>
          <div className='butotnss'>
            <button
              className='btn_brand btn_outlined mt-2'
              onClick={() => window.location.reload()}
            >
              Disconnect
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default DashboardBanner
