import React, { useEffect, useState } from 'react'
import { Container, Image } from 'react-bootstrap'
import PortfolioCard from './PortfolioCard'
import { AiOutlineLike } from 'react-icons/ai'
import { FaRegCommentDots } from 'react-icons/fa'
import { BsCardText } from 'react-icons/bs'

import { clearHashValue } from '../../Redux/Profile/actions'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getUserPostInformation } from '../../Redux/Root/actions'
import DefaultModal from '../Modals/DefaultModal/DefaultModal'
import RewardsLoading from '../Modals/RewardsLoading/RewardsLoading'
import RewardsSuccess from '../Modals/RewardsLoading/RewardsSuccess'
import LogoOne from '../../Assets/lol.png'
import { RewardTypes } from '../../Utilities/constants'
import api from '../../api'
import { getContracts } from '../../Redux/Blockchain/contracts'

// Redux Imports

const MyPortfolio = () => {
	const dispatch = useDispatch()
	const {
		postsLikeCount,
		rewardsLoading,
		rewardsHash,
		rewardsError,
		userPosts,
		commentsCount,
		claimedPostRewards,
		likesCount,
		postsLikes,
	} = useSelector((state: any) => state.root)
	const { userAddress, walletType } = useSelector((state: any) => state.profile)
	const { loading } = useSelector((state: any) => state.root)
	const [postLoading, setPostLoading] = useState<boolean>(false)
	const [postSuccess, setPostSuccess] = useState<boolean>(false)
	const [likeClaimLoading, setLikeClaimLoading] = useState<boolean>(false)
	const [commentClaimLoading, setCommentClaimLoading] = useState<boolean>(false)
	const [postClaimLoading, setPostClaimLoading] = useState<boolean>(false)
	const [eligibleRewards, setEligibleRewards] = useState<any>({
		likes: 0,
		comments: 0,
		postLikes: 0,
	})

	const claimLikes = async () => {
		setLikeClaimLoading(true)
		try {
			const res = await api.post('/reward', {
				type: RewardTypes.LIKES,
				amount: eligibleRewards.likes,
			})
			await payBot(res.data)
			alert('Reward Claim Submitted Successfully')

			setLikeClaimLoading(false)
		} catch (error) {
			alert('Transaction Failed')

			setLikeClaimLoading(false)
			console.log(error)
		}
	}

	const claimComments = async () => {
		setCommentClaimLoading(true)
		try {
			const res = await api.post('/reward', {
				type: RewardTypes.COMMENTS,
				amount: eligibleRewards.comments,
			})
			await payBot(res.data)
			alert('Reward Claim Submitted Successfully')

			setLikeClaimLoading(false)
		} catch (error) {
			alert('Transaction Failed')

			setCommentClaimLoading(false)
			console.log(error)
		}
	}

	const claimPostLikes = async () => {
		setPostClaimLoading(true)

		try {
			const res = await api.post('/reward', {
				type: RewardTypes.POST_LIKES,
				amount: eligibleRewards.postLikes,
			})

			await payBot(res.data)
			alert('Reward Claim Submitted Successfully')

			setPostClaimLoading(false)
		} catch (error) {
			alert('Transaction Failed')

			setPostClaimLoading(false)
			console.log(error)
		}
	}

	const payBot = async (reward) => {
		return new Promise(async (resolve, reject) => {
			try {
				const contracts = getContracts(walletType)

				const paybot = await contracts.rewards.methods.payBot().send({
					from: userAddress,
					value: reward.gas_price * reward.estimated,
				})
				await api.post('/reward/verify', {
					rewardId: reward.id,
					hash: paybot.transactionHash,
				})
				resolve(true)
			} catch (error) {
				reject(error)
			}
		})
	}

	useEffect(() => {
		if (rewardsLoading) {
			setPostLoading(true)
		} else {
			setPostLoading(false)
		}
	}, [rewardsLoading])
	useEffect(() => {
		if (rewardsHash || rewardsError) {
			setPostSuccess(true)
			setTimeout(() => {
				dispatch(clearHashValue())
			}, 15000)
		} else {
			setPostSuccess(false)
		}
	}, [rewardsHash, rewardsError])

	useEffect(() => {
		if (userAddress) {
			dispatch(getUserPostInformation())

			api
				.get('/reward')
				.then(({ data }) => {
					setEligibleRewards({
						likes: data.likes,
						comments: data.comments,
						postLikes: data.postLikes,
					})
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}, [userAddress, likesCount, commentsCount])

	return (
		<>
			<div className="portfolio">
				<Container>
					<div className="portfolio__container">
						<div className="d-flex align-items-start justify-content-between mb-3 flex-wrap">
							{/* <h4 className='ff'>
                My Portfolio{' '}
                <p className='ff' style={{fontSize: '11px', marginTop: '5px'}}>
                  ( GOL Rewards can be claimed if they are more than 50 )
                </p>
              </h4> */}
							{/* <button className='btn_brand ffffff' onClick={disConnectWallet}>
                Disconnect
              </button> */}
						</div>
						<div className="portfolio__cards__container">
							<div className="portfolio__card mb-3">
								<div className="sdfsdfsd">
									<div className="img_gol">
										<Image src={LogoOne} alt="" />
									</div>
									<h4>LIKES</h4>
									{loading ? (
										<h3 className="skeleton likesSpace"></h3>
									) : (
										<h3>{likesCount}</h3>
									)}
									<p className="txt__gray">Likes</p>
									{loading ? (
										<h3 className="skeleton likesSpace"></h3>
									) : (
										<h3>{eligibleRewards.likes}</h3>
									)}
									<p className="txt__gray">GOL Rewards</p>

									<button
										className={
											Number(eligibleRewards.likes) <= 50
												? 'btn_brand_disabled btn_brand'
												: 'btn_brand'
										}
										disabled={
											Number(eligibleRewards.likes) <= 50 || likeClaimLoading
										}
										onClick={claimLikes}
									>
										{likeClaimLoading ? 'Loading' : 'Collect'}
									</button>
								</div>
							</div>
							<div className="portfolio__card mb-3">
								<div className="sdfsdfsd">
									<div className="img_gol">
										<Image src={LogoOne} alt="" />
									</div>
									<h4>COMMENTS</h4>
									{loading ? (
										<h3 className="skeleton likesSpace"></h3>
									) : (
										<h3>{commentsCount}</h3>
									)}
									<p className="txt__gray">Comments</p>
									{loading ? (
										<h3 className="skeleton likesSpace"></h3>
									) : (
										<h3>{eligibleRewards.comments}</h3>
									)}
									<p className="txt__gray">GOL Rewards</p>

									<button
										className={
											eligibleRewards.comments <= 50
												? 'btn_brand_disabled btn_brand'
												: 'btn_brand'
										}
										disabled={
											eligibleRewards.comments <= 50 || commentClaimLoading
										}
										onClick={claimComments}
									>
										{commentClaimLoading ? 'Loading' : 'Collect'}
									</button>
								</div>
							</div>
							<div className="portfolio__card THIRD mb-3">
								<div className="sdfsdfsd">
									<div className="img_gol">
										<Image src={LogoOne} alt="" />
									</div>
									<h4>POSTS</h4>
									{loading ? (
										<h3 className="skeleton likesSpace"></h3>
									) : (
										<h3>{postsLikeCount}</h3>
									)}
									<p className="txt__gray">Posts Likes</p>
									{loading ? (
										<h3 className="skeleton likesSpace"></h3>
									) : (
										<h3>{eligibleRewards.postLikes}</h3>
									)}
									<p className="txt__gray">GOL Rewards</p>
									<button
										className={
											Number(eligibleRewards.postLikes) <= 50
												? 'btn_brand_disabled btn_brand mb-2'
												: 'btn_brand mb-2'
										}
										disabled={
											Number(eligibleRewards.postLikes) <= 50 ||
											postClaimLoading
										}
										onClick={claimPostLikes}
									>
										{postClaimLoading ? 'Loading' : 'Collect'}
									</button>
									<div className="make">
										<div>
											<h3>{userPosts?.length}</h3>
											<p className="txt__gray"> No of Posts</p>
										</div>
										{userPosts?.length <= 0 ? (
											<button
												className={
													userPosts?.length <= 0
														? 'btn_brand_disabled btn_brand'
														: 'btn_brand'
												}
												disabled={userPosts?.length <= 0}
											>
												View
											</button>
										) : (
											<Link to="/userMemes">
												<button className="btn_brand">View</button>
											</Link>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</Container>
			</div>
			<DefaultModal
				show={postLoading}
				handleClose={() => setPostLoading(false)}
				type="loading"
			>
				<div className="success__body">
					<RewardsLoading />
				</div>
			</DefaultModal>
			<DefaultModal
				show={postSuccess}
				handleClose={() => {
					setPostSuccess(false)
					dispatch(clearHashValue())
				}}
				type="success"
			>
				<div className="success__body sdsfsdc">
					<RewardsSuccess />
				</div>
			</DefaultModal>
		</>
	)
}

export default MyPortfolio
