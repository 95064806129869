import {
	CONNECT_WALLET,
	PROFILE_REQUEST,
	PROFILE_SUCCESS,
	PROFILE_FAIL,
	CLEAR_HASH_VALUE,
} from '../constants'
import Web3 from 'web3'

import { generateNonceMessage, priceConversion } from '../../Utilities/Util'
import { getContracts, metaMaskProvider } from '../Blockchain/contracts'

import API from '../../api'
import { getAuth, signInWithCustomToken } from 'firebase/auth'
import axios from 'axios'

let web3: Web3 | undefined = undefined

// Test Network
// const data = [
//   {
//     chainId: '0x61',
//     chainName: 'Binance Smart Chain Testnet',
//     nativeCurrency: {
//       name: 'BNB',
//       symbol: 'BNB',
//       decimals: 18,
//     },
//     rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
//     blockExplorerUrls: ['https://testnet.bscscan.com/'],
//   },
// ]
// Real Network
const data = [
	{
		chainId: '0x38',
		chainName: 'Binance Smart Chain',
		nativeCurrency: {
			name: 'BNB',
			symbol: 'BNB',
			decimals: 18,
		},
		rpcUrls: ['https://bsc-dataseed.binance.org/'],
		blockExplorerUrls: ['https://bscscan.com'],
	},
]

export const checkAndAddNetwork = () => async (dispatch: any) => {
	web3 = web3 ? web3 : new Web3((window as any).ethereum)
	try {
		await metaMaskProvider.request({
			method: 'wallet_switchEthereumChain',
			params: [{ chainId: data[0]?.chainId }],
		})
	} catch (error: any) {
		console.log(error)
		if (error?.code === 4902) {
			try {
				await metaMaskProvider.request({
					method: 'wallet_addEthereumChain',
					params: data,
				})
			} catch (addError: any) {
				console.error(addError?.message)
			}
		}
	}
}

export const connToMetaMask = () => async (dispatch: any, getState: any) => {
	console.log('connToMetaMask')
	try {
		dispatch(checkAndAddNetwork())
		const accounts = await metaMaskProvider.request({
			method: 'eth_requestAccounts',
		})

		dispatch({
			type: CONNECT_WALLET,
			payload: accounts?.[0],
			walletType: 'MetaMask',
		})

		const authInit = (window as any).authInit
		if (authInit) return
		;(window as any).authInit = await initFirebaseAuthAsPromise(accounts?.[0])

		// user creation will be handled from server
	} catch (error: any) {
		console.error(error?.message)
	}
}

export const initFirebaseAuthAsPromise = async (pubAddr) => {
	const auth = getAuth()
	if (auth.currentUser) {
		await auth.signOut()
	}
	return new Promise(async (resolve, reject) => {
		// get a nonce
		try {
			const { data } = await API.get(`/auth/nonce/${pubAddr}`)

			// generate signature
			web3?.eth.personal.sign(
				generateNonceMessage(data.nonce),
				pubAddr,
				'',
				async (e, sign) => {
					if (e) {
						reject(e)
						return
					}
					const user = await generateTokenAsPromise(pubAddr, sign)
					resolve(user)
				}
			)
		} catch (error) {
			reject(error)
		}
	})
}

const generateTokenAsPromise = async (publicAddress, signature) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.post('/auth', {
				publicAddress,
				signature,
			})
			if (!data) {
				resolve(null)
			}

			axios.defaults.headers = {
				Authorization: 'bearer ' + data,
			} as any

			const user = await signInWithCustomToken(getAuth(), data)

			resolve(user)

			console.log('firebase login success', user)
		} catch (error) {
			console.log(error)

			reject(error)
		}
	})
}

export const getProfileInformation =
	() => async (dispatch: any, getState: any) => {
		try {
			dispatch({
				type: PROFILE_REQUEST,
			})
			const {
				profile: { userAddress, walletType },
			} = getState()

			const { web3, golCoin, fetchPrice } = getContracts(walletType)

			const golBal = await golCoin.methods.balanceOf(userAddress).call()
			const golBalance = priceConversion('fromWei', 'ether', golBal, web3)

			const golInUsdc = await fetchPrice.methods.fetchGOLPriceInDollar().call()

			const golInUSDC = Number(golInUsdc / 10 ** 16)
			const totalGolBalance = Number(golBalance * golInUSDC) //USD

			const isAvalToLike = totalGolBalance >= 1
			const isAvalToPost = totalGolBalance >= 5

			// const golBalance = priceConversion('fromWei', 'ether', golBal, web3)

			dispatch({
				type: PROFILE_SUCCESS,
				payload: {
					golBalance,
					golInUSDC,
					totalGolBalance,
					isAvalToLike,
					isAvalToPost,
				},
			})
		} catch (error: any) {
			dispatch({
				type: PROFILE_FAIL,
				payload: error?.message,
			})
		}
	}

export const clearHashValue = () => async (dispatch: any) => {
	dispatch({
		type: CLEAR_HASH_VALUE,
	})
}
