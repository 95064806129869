import Web3 from 'web3'
import detectEthereumProvider from '@metamask/detect-provider'
import {golCoinAbi, golCoinAddress} from './Abi/golCoin'
import {priceAbi, priceAddress} from './Abi/price'
import {rewardsAbi, rewardsAddress} from './Abi/getRewards'

// mainnet
// const RPC_URL = 'https://bsc-dataseed.binance.org/'

// Testnet

const RPC_URL = 'https://bsc-dataseed.binance.org/'

export let metaMaskProvider: any

const getMetamaskProvider = async () => {
  try {
    metaMaskProvider = await detectEthereumProvider()
  } catch (error: any) {
    console.log(error?.message)
  }
}
getMetamaskProvider()

export const getContracts = (walletType: string) => {
  let web3: any = new Web3(RPC_URL)
  switch (walletType) {
    case 'MetaMask':
      if (metaMaskProvider) {
        web3 = new Web3(metaMaskProvider)
      }
      break

    default:
      web3 = new Web3(RPC_URL)
      break
  }

  // contracts
  const golCoin = new web3.eth.Contract(golCoinAbi, golCoinAddress)
  const fetchPrice = new web3.eth.Contract(priceAbi, priceAddress)
  const rewards = new web3.eth.Contract(rewardsAbi, rewardsAddress)
  return {
    web3,
    golCoin,
    fetchPrice,
    rewards,
  }
}
