import WarningModal from '../WarningModal/WarningModal'
import DefaultModal from '../DefaultModal/DefaultModal'
import { WalletsPopupProps } from '../WalletsPopup/WalletsPopup.d'
import UploadField from '../../FeedContent/UploadField'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { storage, db } from '../../../firebase'
import CreateMemeLoading from '../CreateMemeLoading/CreateMemeLoading'
import CreateMemeSuccess from '../CreateMemeSuccess/CreateMemeSuccess'
import WalletsPopup from '../WalletsPopup/WalletsPopup'

// image
import Tick from '../../../Assets/Tick.svg'
import {
	getDownloadURL,
	ref,
	uploadBytes,
	uploadBytesResumable,
	UploadTaskSnapshot,
} from 'firebase/storage'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { AppCollections } from '../../../Utilities/constants'

const CreatePost = ({ show, handleClose }: WalletsPopupProps) => {
	// redux state
	const { isAvalToPost, userAddress } = useSelector(
		(state: any) => state.profile
	)

	const [imageSrc, setImageSrc] = useState<any>([])
	const [description, setDescription] = useState<string>('')
	const [error, setError] = useState<boolean>(false)
	const [url, setUrl] = useState('')
	const [progress, setProgress] = useState(0)

	// Modals
	const [open, setOpen] = useState(false)
	const [postLoading, setPostLoading] = useState<boolean>(false)
	const [postSuccess, setPostSuccess] = useState<boolean>(false)
	const [warningLoading, setWarningLoading] = useState<boolean>(false)

	const clearAllValues = () => {
		setImageSrc([])
		setDescription('')
		setError(false)
		setUrl('')
		setProgress(0)
	}

	const onUploadErr = (e: Error) => {
		console.error(e)
	}
	const onUploadStateUpdate = (snap: UploadTaskSnapshot) => {
		const progress = Math.round((snap.bytesTransferred / snap.totalBytes) * 100)
		setProgress(progress)
	}

	const uploadImage = async () => {
		const imgRef = ref(storage, `memefiles/${imageSrc[0].name}`)
		const uploadTask = uploadBytesResumable(imgRef, imageSrc[0])
		uploadTask.on(
			'state_changed',
			onUploadStateUpdate,
			onUploadErr,
			async () => {
				const url = await getDownloadURL(imgRef)
				if (!url) return
				setUrl(url)
			}
		)
	}

	useEffect(() => {
		if (description?.length > 0 && imageSrc?.length === 1) {
			setError(false)
		} else {
			setError(true)
		}
	}, [description, userAddress, imageSrc])

	const submitMeme = (e: React.FormEvent<HTMLFormElement>) => {
		handleClose()
		e.preventDefault()
		if (!userAddress) {
			setOpen(true)
		} else if (userAddress) {
			//TODO: add && isAvalToPost later
			setPostLoading(true)
			uploadImage()
		} else {
			setWarningLoading(true)
		}
	}
	useEffect(() => {
		if (url) {
			const newMemeItem = {
				timestamp: serverTimestamp(),
				image: url,
				imageName: imageSrc[0]?.name,
				username: userAddress,
				description: description,
				likes: 0,
				likedUsers: [],
				comments: 0,
				archived: false,
			}

			const memesCollection = collection(db, AppCollections.MEMES)
			addDoc(memesCollection, newMemeItem)
				.then(() => {
					setPostLoading(false)
					setPostSuccess(true)
					clearAllValues()
					setTimeout(() => {
						setPostSuccess(false)
					}, 1000)
				})
				.catch((err) => console.log(err))
		}
	}, [url])
	// wallet popup
	useEffect(() => {
		if (userAddress) {
			setOpen(false)
		}
	}, [userAddress])

	return (
		<>
			<DefaultModal
				title="Post a Meme"
				show={show}
				handleClose={handleClose}
				type="fail"
			>
				<div className="success__body">
					<form className="create_post_wrapper" onSubmit={(e) => submitMeme(e)}>
						<input
							type="text"
							className="shadow-none form-control"
							placeholder="Type something about your meme.."
							value={description}
							onChange={(e) => setDescription(e.target.value)}
						/>
						<p className="sdf">Select Files</p>
						<UploadField setImageSrc={setImageSrc} />
						<button
							disabled={error}
							style={{ borderRadius: '33px' }}
							className={
								error ? 'btn_brand mt-3 btn_brand_disabled' : 'btn_brand mt-3'
							}
							type="submit"
						>
							Upload
						</button>
					</form>
				</div>
			</DefaultModal>
			<DefaultModal
				show={postLoading}
				handleClose={() => setPostLoading(false)}
				type="loading"
			>
				<div className="success__body">
					<CreateMemeLoading progress={progress} />
				</div>
			</DefaultModal>
			<DefaultModal
				show={postSuccess}
				handleClose={() => setPostSuccess(false)}
				type="success"
			>
				<div className="success__body">
					<CreateMemeSuccess />
				</div>
			</DefaultModal>
			<WarningModal
				show={warningLoading}
				handleClose={() => setWarningLoading(false)}
				warningType="post"
			/>
			<WalletsPopup show={open} handleClose={() => setOpen(false)} />
		</>
	)
}

export default CreatePost
