import {Container} from 'react-bootstrap'
import {BsArrowLeft} from 'react-icons/bs'
import {Link} from 'react-router-dom'

// Svgs
// import ImgOne from '../../Assets/LandingPage/Smart_fluid.svg'
// import RoboCoin from '../../Assets/LandingPage/robodogeCoin.svg'

const NotFound = () => {
  return (
    <div className='notfound'>
      <Container>
        <div className='notfound__wrapper'>
          <div className='fluid__wrapper'>
            <h1>404</h1>
          </div>
          <h4 className='lol'> Page Not Found</h4>
          <p>Sorry, we couldn't find the page you're looking for.</p>
          <Link to='/'>
            <button className='btn_brand'>
              <span>
                <BsArrowLeft />
              </span>{' '}
              Back To Home
            </button>
          </Link>
        </div>
      </Container>
    </div>
  )
}

export default NotFound
