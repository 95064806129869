import MetaTags from 'react-meta-tags'

const Meta = ({meme}) => {
  return (
    <MetaTags>
      <meta property='og:type' content='website' />
      <meta property='og:url' content={window.location.href} />
      <meta property='og:title' content={meme?.description} />
      <meta property='og:site_name' content='Game Of Laughs' />
      <meta
        property='og:description'
        content='Game of Laughs is a meme to earn platform that rewards you for creating memes and interacting on the platform.'
      />
      <meta property='og:image' content={meme?.image} />
      <meta name='twitter:title' content={meme?.description} />
      <meta
        name='twitter:description'
        content='Game of Laughs is a meme to earn platform that rewards you for creating memes and interacting on the platform.'
      />
      <meta name='twitter:image' content={meme?.image} />
      <meta name='twitter:card' content={meme?.description} />
    </MetaTags>
  )
}

export default Meta
