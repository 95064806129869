import {
  Col,
  Container,
  Form as FormInputField,
  Image,
  Row,
} from 'react-bootstrap'
import {FaFacebook, FaYoutube, FaTwitter, FaReddit, FaTelegram, FaInstagram} from 'react-icons/fa'
import * as Yup from 'yup'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import ContactSuccess from '../../Modals/ContactSuccess/ContactSuccess'
import {useState} from 'react'

import WinterHere from '../../../Assets/LandingPage/winter-is-here.png'
import { GrFacebookOption } from 'react-icons/gr'

const NewsLetter = () => {
  const [show, setShow] = useState(false)

  // Yup validation schema
  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid Email Format')
      .required('Email is Required'),
  })

  // formik
  const initialValues = {
    email: '',
  }
  const submitContactForm = (values: any, onSubmitProps: any) => {
    onSubmitProps.setSubmitting(false)
    onSubmitProps.resetForm()
    setShow(true)
  }
  return (
    <>
      <section className='newsLetter'>
        <Container fluid className='p-0' >
          <div className='newsLetter_wrapper'>
            <Row>
              <Col xs={12} sm={12} md={12} lg={6} xl={6} className='mb-3 sss'>
                <div className='msds'>
                  <div className='sldncsd'>
                    <div className='section_info'>
                      {/* <p className='section_small_heading'>Future of Memes</p> */}
                      <h1 className='section_heading'>
                        <span>Join the</span> Game Of Laughs Family
                      </h1>
                    </div>
                    <p className='ewfew'>
                      Subscribe to us to join the big family of laughs. Follow
                      and subscribe to us below
                    </p>
                    {/* <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={submitContactForm}
                    >
                      {(formik) => {
                        return (
                          <Form className='newsletter_form'>
                            <FormInputField.Group className='mb-3'>
                              <Field
                                type='email'
                                placeholder='Email Address'
                                className=' shadow-none form-control'
                                name='email'
                              />
                              <ErrorMessage component='span' name='email' />
                            </FormInputField.Group>

                            <button
                              disabled={
                                !(formik.dirty && formik.isValid) ||
                                formik.isSubmitting
                              }
                              className={
                                !(formik.dirty && formik.isValid) ||
                                formik.isSubmitting
                                  ? 'btn_brand btn_brand_disabled mb-3'
                                  : 'btn_brand mb-3'
                              }
                            >
                              subscribe
                            </button>
                          </Form>
                        )
                      }}
                    </Formik> */}
                    <div className='social_links'>
                      <a
                        href='https://www.facebook.com/gameoflaughs/'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <GrFacebookOption />
                      </a>
                      <a
                        href='https://www.instagram.com/thegameoflaughs/'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <FaInstagram />
                      </a>
                      <a
                        href='https://twitter.com/gameoflaughs'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <FaTwitter />
                      </a>
                      <a
                        href='https://t.me/GameOfLaughs'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <FaTelegram />
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6} className='mb-3 ssss'>
                <div className='img_s'>
                  <Image src={WinterHere} alt='' />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <ContactSuccess
        type='news_letter'
        show={show}
        handleClose={() => setShow(false)}
      />
    </>
  )
}

export default NewsLetter
