import {Col, Container, Image, Row} from 'react-bootstrap'

import {FaInstagram, FaTwitter, FaTelegram} from 'react-icons/fa'
import {GrFacebookOption} from 'react-icons/gr'
import {IoIosRocket} from 'react-icons/io'
import {HiPencilAlt} from 'react-icons/hi'
import {Link} from 'react-router-dom'

// Svgs
// import ImgOne from '../../../Assets/LandingPage/women-with-vr.png'

const Banner = () => {
  return (
    <>
      <section className='banner'>
        <Container fluid className='p-0'>
          <Row>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className='banner_center '>
                {/* <p className='section_small_heading'>Introducing</p> */}
                <h1 className='section_heading'>
                  <span>Introducing</span> Game of Laughs
                </h1>
                <p className='kir'>
                  A Meme to Earn platform that rewards their users for their
                  time and efforts
                </p>
                <p>
                  Explore on the world's best Meme Platform that rewards you for
                  creating memes and interacting on the platform
                </p>
                <div className='banner_buttons'>
                  <Link to='/memes'>
                    <button className='btn_brand'>
                      <span>
                        <IoIosRocket />
                      </span>
                      <span>Browse Memes</span>
                    </button>
                  </Link>
                </div>
                <p className='social_txt text-left'>
                  Follow our social channels and join our movement.
                </p>
                <div className='social_links'>
                  <a
                    href='https://www.facebook.com/gameoflaughs/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <GrFacebookOption />
                  </a>
                  <a
                    href='https://www.instagram.com/thegameoflaughs/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <FaInstagram />
                  </a>
                  <a
                    href='https://twitter.com/gameoflaughs'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <FaTwitter />
                  </a>
                  <a
                    href='https://t.me/GameOfLaughs'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <FaTelegram />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Banner
