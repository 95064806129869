import {
	CLEAR_HASH_VALUE,
	COLLECT_REWARDS_FAIL,
	COLLECT_REWARDS_REQUEST,
	COLLECT_REWARDS_SUCCESS,
	GET_USER_DATA,
	GET_USER_POSTS,
	SET_THEME_MODE,
} from '../constants'

const initialState = {
	themeMode: 'dark',
	dailyComments: 0,
	todaysDate: '',
	userPosts: [],
	postsLikeCount: 0,
	likesCount: 0,
	commentsCount: 0,
	postsLikes: 0,
	rewardsHistory: [],
	rewardsLoading: false,
	rewardsError: false,
	rewardsHash: '',
	claimedPostRewards: 0,
	loading: true,
}

export const mainReducer = (state = initialState, action: any) => {
	switch (action.type) {
		case CLEAR_HASH_VALUE:
			return {
				...state,
				rewardsLoading: false,
				rewardsError: false,
				rewardsHash: '',
			}
		case COLLECT_REWARDS_REQUEST:
			return {
				...state,
				rewardsLoading: true,
				rewardsError: false,
			}
		case COLLECT_REWARDS_SUCCESS:
			return {
				...state,
				rewardsLoading: false,
				rewardsHash: action.payload,
			}
		case COLLECT_REWARDS_FAIL:
			return {
				...state,
				rewardsLoading: false,
				rewardsError: action.payload,
			}
		case SET_THEME_MODE:
			return {
				...state,
				themeMode: action.payload,
			}
		case GET_USER_POSTS:
			const totalLikes = action.payload.reduce((total, post) => {
				return (total += post.likedUsers.length)
			}, 0)

			return {
				...state,
				userPosts: action.payload,
				postsLikes: totalLikes,
			}
		case GET_USER_DATA:
			return {
				...state,
				commentsCount: action.payload.commentsCount,
				likesCount: action.payload.likesCount,
				rewardsHistory: action.payload.rewardsHistory,
				postsLikeCount: action.payload.postsLikesCount,
				claimedPostRewards: action.payload.postRewardsClaimed,
				loading: false,
				dailyComments: action.payload.todayCommentsCount,
				todaysDate: action.payload.date,
			}

		default:
			return state
	}
}
