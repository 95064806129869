// export const getAddress = (address) => {
//   const add1 = address.substring(0, 2)
//   const add2 = address.substring(address.length - 4)
//   const finalAdd = `${add1}....${add2}`
//   return finalAdd
// }

export const numberFormate = (number: number | string) => {
	const res = Number(number)?.toLocaleString(navigator.language, {
		minimumFractionDigits: 4,
	})
	return Number(res)
}
export const numberFormateWithOutDecimals = (number: number | string) => {
	const res = Number(number)?.toLocaleString(navigator.language, {
		minimumFractionDigits: 0,
	})
	return Number(res)
}

export const createBonus = (
	totalAmount: number | string,
	bonusRatio: number
) => {
	const bonus = (Number(totalAmount) / 100) * bonusRatio
	const result = (Number(totalAmount) + bonus)?.toFixed(2)
	return result
}

export const formateDate = (milliSeconds: number) => {
	const newDate = new Date(milliSeconds * 1000)
	const [years, months, days, hours, minutes, seconds] = [
		newDate.getFullYear(),
		newDate.getMonth() + 1,
		newDate.getDate(),
		newDate.getHours(),
		newDate.getMinutes(),
		newDate.getSeconds(),
	]
	return [years, months, days, hours, minutes, seconds]
}

export const calculatePercentage = (
	totalAmount: number | string,
	percentage: number
) => {
	const percentageAmount = (Number(percentage) / 100) * Number(totalAmount)
	const result = Number(totalAmount) - percentageAmount
	return result
}

export const priceConversion = (
	type: string,
	formate: string,
	amount: number | string,
	web3: any
) => {
	if (type === 'fromWei') {
		const price = web3.utils.fromWei(amount.toString(), formate)
		return price
	}
	if (type === 'toWei') {
		const price = web3.utils.toWei(amount.toString(), formate)
		return price
	}
}

export const gasPrice = async (web3: any, number: number) => {
	const gasPrice = await web3.eth.getGasPrice()
	if (number) {
		const newGasPrice = web3.utils.toHex(Number(gasPrice * number)?.toFixed(0))
		return newGasPrice
	} else {
		const newGasPrice = web3.utils.toHex(Number(gasPrice * 4.5)?.toFixed(0))
		return newGasPrice
	}
}

export const getTheTimeDifference = (sec: number) => {
	const timeInSec = Number(sec) * 1000
	const currentTimeInSec = new Date().getTime()
	const difference = timeInSec - currentTimeInSec
	if (difference > 0) {
		return true
	} else {
		return false
	}
}

export const generateNonceMessage = (nonce: string) => {
	return 'I am signing with my one-time key: ' + nonce
}
