import {Image} from 'react-bootstrap'

// image
import Tick from '../../../Assets/Tick.svg'

const CreateMemeSuccess = () => {
  return (
    <div className='success__body'>
      <Image src={Tick} alt='' className='mb-3 loader' />
      <h4 className='mt-3'>Meme created successfully</h4>
    </div>
  )
}

export default CreateMemeSuccess
