export const CONNECT_WALLET: string = 'CONNECT_WALLET'
export const PROFILE_REQUEST: string = 'PROFILE_REQUEST'
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS'
export const PROFILE_FAIL: string = 'PROFILE_FAIL'
export const SET_THEME_MODE: string = 'SET_THEME_MODE'
export const GET_USER_DATA: string = 'GET_USER_DATA'
export const GET_USER_POSTS: string = 'GET_USER_POSTS'

export const COLLECT_REWARDS_REQUEST: string = 'COLLECT_REWARDS_REQUEST'
export const COLLECT_REWARDS_SUCCESS: string = 'COLLECT_REWARDS_SUCCESS'
export const COLLECT_REWARDS_FAIL: string = 'COLLECT_REWARDS_FAIL'

export const CLEAR_HASH_VALUE: string = 'CLEAR_HASH_VALUE'
