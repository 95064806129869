import {
  CONNECT_WALLET,
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_FAIL,
} from '../constants'

const initialState = {
  profileLoading: false,
  profileError: false,
  userAddress: '',
  walletType: '',
  golBalance: 0,
  golInUSDC: 0,
  totalGolBalance: 0,
  isAvalToLike: false,
  isAvalToPost: false,
}

export const profileReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CONNECT_WALLET:
      return {
        ...state,
        userAddress: action.payload,
        walletType: action.walletType,
      }
    case PROFILE_REQUEST:
      return {
        ...state,
        profileLoading: true,
        profileError: false,
      }
    case PROFILE_SUCCESS:
      return {
        ...state,
        profileLoading: false,
        golBalance: action.payload.golBalance,
        golInUSDC: action.payload.golInUSDC,
        totalGolBalance: action.payload.totalGolBalance,
        isAvalToLike: action.payload.isAvalToLike,
        isAvalToPost: action.payload.isAvalToPost,
      }
    case PROFILE_FAIL:
      return {
        ...state,
        profileLoading: false,
        profileError: action.payload,
      }

    default:
      return state
  }
}
