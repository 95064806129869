import { collection, doc, getDocs, limit, onSnapshot, orderBy, query, QuerySnapshot, startAfter, where } from 'firebase/firestore'
import {useEffect, useState} from 'react'
import db from '../../../firebase'
import { AppCollections } from '../../../Utilities/constants'
import FeedCard from '../FeedCard/FeedCard'
import FeedCardLoading from '../FeedCard/FeedCardLoading'
import FeedBanner from './FeedBanner'

const perPageCount = 10

const FeedWrapper = () => {
  const [memes, setMemes] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const [lastVisible, setLastVisible] = useState(null)
  const [disableBtn, setDisableBtn] = useState(false)
  const [loadingBtn, setLoadingBtn] = useState(false)

  const getAllMemes = () => {
    const q = query(collection(db, AppCollections.MEMES), where('archived', '==', false), orderBy('timestamp', 'desc'), limit(perPageCount))
    return onSnapshot(q, ({docs,empty})=>{
      setLoading(false)
      if(empty)return
      setLastVisible(docs[docs.length-1] as any)
      setMemes(docs.map((d)=> ({
        id:d.id,
        data: d.data()
      })))
    })
   
   
  }
  const getNextMemes = async () => {
    if(!lastVisible)return
    setLoadingBtn(true)
    const ref = collection(db, AppCollections.MEMES)
      const q = query(ref,where('archived', '==', false), orderBy('timestamp', 'desc'), limit(perPageCount), startAfter(lastVisible))

      const {empty, docs} = await getDocs(q)
      setLoading(false)
      if(empty){
        setDisableBtn(true)
        return
      }

      setLastVisible(docs[docs.length-1] as any)
      setMemes([...memes, ...docs.map((d)=> ({
        id:d.id,
        data: d.data()
      }))])
      setLoadingBtn(false)

  }

  useEffect(() => {
    return getAllMemes()
  }, [])

  return (
    <>
      <FeedBanner />
      <section className='feedWrapper'>
        <div className='feedWrapper__container'>
          {memes?.map((meme: any) => (
            <FeedCard data={meme?.data} id={meme?.id} key={meme?.id} />
          ))}
          {loading && <FeedCardLoading count={8} />}
          {!loading && !disableBtn && (
            <div className='text-center'>
              <button
                className='btn_brand m-auto'
                style={{borderRadius: '33px'}}
                onClick={getNextMemes}
              >
                {loadingBtn ? 'Loading...' : 'Load More'}
              </button>
            </div>
          )}
        </div>
      </section>
    </>
  )
}

export default FeedWrapper
