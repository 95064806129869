import {Image} from 'react-bootstrap'

// image
import Loader from '../../../Assets/Loader.svg'
interface CreateMemeLoadingProps {
  progress?: number
}

const CreateMemeLoading = ({progress}: CreateMemeLoadingProps) => {
  return (
    <div className='success__body'>
      <Image src={Loader} alt='' className='mb-3 update__spinner loader' />
      <h4>{progress}%</h4>
      <h4 className='mt-3'>Creating a Meme...</h4>
    </div>
  )
}

export default CreateMemeLoading
