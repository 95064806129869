import {useEffect, useMemo} from 'react'
import {useDropzone} from 'react-dropzone'

const UploadField = ({setImageSrc}) => {
  // styles
  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#ffffffe6',
    borderStyle: 'dashed',
    backgroundColor: 'transparent',
    color: '#fafafa',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  }

  const activeStyle = {
    borderColor: '#2196f3',
  }

  const acceptStyle = {
    borderColor: '#00e676',
  }

  const rejectStyle = {
    borderColor: '#dc3545',
  }

  //   ###############################################################3333
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    fileRejections,
  } = useDropzone({accept: 'image/jpeg, image/png', maxFiles: 1})

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  useEffect(() => {
    setImageSrc(acceptedFiles)
  }, [acceptedFiles])

  const acceptedFileItems = acceptedFiles.map((file) => (
    <li className='fileName' key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  const fileRejectionItems = fileRejections.map(({file, errors}) => {
    return (
      <>
        <li className='fileName' key={file.path}>
          {file.path} - {file.size} bytes
        </li>
        {errors.map((e) => (
          <p className='fileNameError' key={e.code}>
            {e.message}
          </p>
        ))}
      </>
    )
  })

  return (
    <div className='upload__field'>
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
        <p>(Only *.jpeg and *.png images will be accepted)</p>
      </div>
      <aside className='mt-3'>
        <h6>Files</h6>
        <ul>{acceptedFileItems}</ul>
        <ul>{fileRejectionItems}</ul>
      </aside>
    </div>
  )
}

export default UploadField
