import {Image} from 'react-bootstrap'
import DefaultModal from '../DefaultModal/DefaultModal'
import {WalletsPopupProps} from '../WalletsPopup/WalletsPopup.d'
import {useSelector} from 'react-redux'

// svgs
import Token from '../../../Assets/LogoOne.png'
import {numberFormate} from '../../../Utilities/Util'

const WarningModal = ({show, handleClose, warningType}: WalletsPopupProps) => {
  const {golInUSDC} = useSelector((state: any) => state.profile)

  return (
    <DefaultModal
      title='No Sufficient Funds'
      show={show}
      handleClose={handleClose}
      type='fail'
    >
      <div className='success__body'>
        {warningType === 'like' ? (
          <>
            <Image className='gol_img' src={Token} alt='' />
            <h5>1 GOL : {numberFormate(golInUSDC)} USD</h5>
            <h6 className='mt-3'>
              You should have minimum 1$ worth of GOL to like / comment
            </h6>
            <a
              target='_blank'
              href='https://pancakeswap.finance/swap?outputCurrency=0x21702036f0a6076C56421B04Ca41F4fF5EC99344'
              rel='noopener noreferrer'
            >
              <button className='btn_brand mt-2'>Buy GOL on Pancake</button>
            </a>
          </>
        ) : warningType === 'post' ? (
          <>
            <Image className='gol_img' src={Token} alt='' />
            <h5>1 GOL : {numberFormate(golInUSDC)} USD</h5>
            <h6 className='mt-3'>
              You should have minimum 5$ worth of GOL to Post Meme
            </h6>
            <a
              target='_blank'
              href='https://pancakeswap.finance/swap?outputCurrency=0x21702036f0a6076C56421B04Ca41F4fF5EC99344'
              rel='noopener noreferrer'
            >
              <button className='btn_brand mt-2'>Buy GOL on Pancake</button>
            </a>
          </>
        ) : (
          ''
        )}
      </div>
    </DefaultModal>
  )
}

export default WarningModal
