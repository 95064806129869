import db from '../../firebase'

import {
	COLLECT_REWARDS_FAIL,
	COLLECT_REWARDS_REQUEST,
	COLLECT_REWARDS_SUCCESS,
	GET_USER_DATA,
	GET_USER_POSTS,
	SET_THEME_MODE,
} from '../constants'
import format from 'date-fns/format'
import { getProfileInformation } from '../Profile/actions'
import {
	collection,
	doc,
	getDocs,
	onSnapshot,
	orderBy,
	query,
	where,
	addDoc,
} from 'firebase/firestore'
import { AppCollections, RewardTypes } from '../../Utilities/constants'
import { getAuth } from 'firebase/auth'

export const setThemeMode = () => async (dispatch: any) => {
	const get = localStorage.getItem('themeMode')
	if (get) {
		dispatch({
			type: SET_THEME_MODE,
			payload: get,
		})
	} else {
		localStorage.setItem('themeMode', 'dark')
	}
}

export const setThemeColor =
	(mode: string) => async (dispatch: any, getState: any) => {
		localStorage.setItem('themeMode', mode)
		dispatch({
			type: SET_THEME_MODE,
			payload: mode,
		})
	}

export const getUserData = () => async (dispatch: any, getState: any) => {
	let unsubscribe: any

	const w = window as any
	if (w.userListener) {
		w.userListener()
	}

	try {
		const userAddress = getAuth().currentUser?.uid
		if (!userAddress) return
		const userDoc = doc(db, AppCollections.USERS, userAddress)
		unsubscribe = onSnapshot(userDoc, (doc) => {
			console.log('doc', doc.id)
			if (!doc.exists()) return
			dispatch({
				type: GET_USER_DATA,
				payload: doc.data(),
			})
		})
		w.userListener = unsubscribe
		return unsubscribe
	} catch (error) {
		if (unsubscribe) unsubscribe()
		console.log(error)
		return () => {}
	}
}

export const getUserPostInformation =
	() => async (dispatch: any, getState: any) => {
		try {
			const {
				profile: { userAddress },
			} = getState()
			const q = query(
				collection(db, AppCollections.MEMES),
				where('archived', '==', false),
				where('username', '==', userAddress),
				orderBy('timestamp', 'desc')
			)
			const { docs, empty } = await getDocs(q)
			if (empty) return
			dispatch({
				type: GET_USER_POSTS,
				payload: docs.map((d) => ({ ...d.data(), id: d.id })),
			})
		} catch (error) {
			console.log(error)
		}
	}
