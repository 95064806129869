import { initializeApp } from 'firebase/app'
import { getStorage } from 'firebase/storage'
import { getFirestore } from 'firebase/firestore'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
	apiKey: 'AIzaSyBRvLSjpYqv4cMJDnZd-Qy6F3tRgTkl048',
	authDomain: 'game-of-laughs-prod.firebaseapp.com',
	projectId: 'game-of-laughs-prod',
	storageBucket: 'game-of-laughs-prod.appspot.com',
	messagingSenderId: '503725021995',
	appId: '1:503725021995:web:de8d6bbeeb29c7188a836e',
	measurementId: 'G-D9PKQH06EX',
}

// Initialize Firebase

const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
export const analytics = getAnalytics(app)
export const storage = getStorage(app)
export default db
