const FeedCardLoading = ({count}: any) => {
  return (
    <>
      {Array(10)
        .fill(0, 0, count)
        .map((i, index) => (
          <div className='feed_card loading_card' key={index}>
            <h5 className='skeleton'></h5>
            <div className='feed_card_image skeleton'></div>
            <div className='feed_card_actions'>
              <div className='icon_wrapper skeleton'></div>
              <div className='icon_wrapper skeleton'></div>
              <div className='icon_wrapper skeleton'></div>
            </div>
          </div>
        ))}
    </>
  )
}

export default FeedCardLoading
