import {Col, Container, Image, Row} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {FaRegEnvelope} from 'react-icons/fa'

// Svgs
import Logo from '../../Assets/Logo.png'
import LogoOne from '../../Assets/lol.png'

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col xs={12} sm={12} md={4} lg={4} xl={4} className="c">
            <Link to="/" className="dssd">
              <Image src={LogoOne} alt="" className="imgg" />
              <h2>Game of Laughs</h2>
            </Link>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} xl={4} className='text-center c'>
            <a
              href='https://www.termsandcondiitionssample.com/live.php?token=fXxFTjPBIOseksm9JpFTgJ9nyWrf9pDh'
              className='text-decoration-underline'
              target='_blank'
            >
              Terms & Conditions
            </a>
            <p className='copyright'>
              Copyright &copy; {new Date().getFullYear()}. All rights reserved
              by Game of Laughs
            </p>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} xl={4} className="text-center c">
            {/* <p className='sdsdf'>Mail us at</p>
            <span className='envelope_wrapper'>
              <FaRegEnvelope />
            </span>
            <a href='mailto:Info@gameoflaughs.com'>Info@gameoflaughs.com</a> */}
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer
