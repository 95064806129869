import { useEffect, useLayoutEffect, useState } from 'react'
import { MdDarkMode, MdOutlineLightMode } from 'react-icons/md'
import { Container, Nav, Image, Navbar } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { BsWallet2 } from 'react-icons/bs'
import WalletsPopup from '../Modals/WalletsPopup/WalletsPopup'
import DisConnect from '../Modals/DisConnect/DisConnect'

// Svgs
import LogoOne from '../../Assets/lol.png'
import MetaMask from '../../Assets/MetaMask.svg'

//  redux imports
import { useSelector, useDispatch } from 'react-redux'
import {
	getUserData,
	setThemeColor,
	setThemeMode,
} from '../../Redux/Root/actions'
import {
	checkAndAddNetwork,
	getProfileInformation,
} from '../../Redux/Profile/actions'
import { useLocation } from 'react-router-dom'
import format from 'date-fns/format'
import { collection, doc, updateDoc } from 'firebase/firestore'
import { AppCollections } from '../../Utilities/constants'
import db from '../../firebase'

const Header = () => {
	var result = format(new Date(), 'dd/MM/yyyy')
	// redux state
	const dispatch = useDispatch()
	const { userAddress, walletType } = useSelector((state: any) => state.profile)
	const { themeMode, todaysDate } = useSelector((state: any) => state.root)

	const [open, setOpen] = useState(false)
	const [openDisconnectModal, setOpenDisconnectModal] = useState(false)
	const { pathname } = useLocation()

	// wallet popup
	useEffect(() => {
		if (userAddress) {
			setOpen(false)
			dispatch(getProfileInformation())
		}
	}, [userAddress])

	// TODO: decide on it later

	// useEffect(() => {
	//   if (userAddress && todaysDate != result) {
	//     const userDoc = doc(db, AppCollections.USERS, userAddress.toString())
	//     updateDoc(userDoc, {
	//       date: result,
	//       todayCommentsCount: 0,
	//     })
	//   }
	// }, [userAddress, todaysDate, result])

	// dark and light mode
	// const [darkMode, setDarkMode] = useState(false)

	const setDarkAndLightMode = () => {
		if (themeMode === 'light') {
			dispatch(setThemeColor('dark'))
		} else if (themeMode === 'dark') {
			dispatch(setThemeColor('light'))
		}
	}

	useLayoutEffect(() => {
		dispatch(setThemeMode())
	}, [])

	useEffect(() => {
		if (themeMode === 'light') {
			// document.body.classList.add('lightTheme')
			// document.body.classList.remove('darkTheme')
			document.body.classList.add('darkTheme')
			document.body.classList.remove('lightTheme')
		} else if (themeMode === 'dark') {
			document.body.classList.add('darkTheme')
			document.body.classList.remove('lightTheme')
		}
	}, [themeMode])

	useEffect(() => {
		console.log('scrollTop')
		window.scrollTo(0, 0)
		if (pathname != '/') {
			const NavItem = document.querySelector('.main')
			NavItem?.classList.remove('active')
		}
		if (pathname === '/') {
			const navList = document.querySelectorAll('.nav-link')
			navList.forEach((item: any) => item.classList.remove('active'))
			const NavItem = document.querySelector('.main')
			NavItem?.classList.add('active')
		}
	}, [pathname])

	return (
		<>
			<Navbar
				collapseOnSelect
				expand="lg"
				variant="dark"
				fixed="top"
				className="navbar navbar-expand-lg navbar-dark fixed-top"
			>
				<Container>
					{/* <div className=''> */}
					{/* <div className='mob'>
            {themeMode === 'light' ? (
              <MdDarkMode className='moon_icon' onClick={setDarkAndLightMode} />
            ) : (
              <MdOutlineLightMode
                className='sun_icon'
                onClick={setDarkAndLightMode}
              />
            )}
          </div> */}
					{/* <a href='https://www.gameoflaughs.com/'> */}
					<LinkContainer to="/">
						<Navbar.Brand style={{ cursor: 'pointer' }}>
							<Image
								src={LogoOne}
								alt=""
								// className='align-top'
							/>
							Game Of Laughs
						</Navbar.Brand>
					</LinkContainer>
					{/* </a> */}
					{/* </div> */}

					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse id="responsive-navbar-nav">
						<Nav className="m-auto nav__ mx-auto">
							<div className="navbar__left">
								<LinkContainer to="/" className="main">
									<Nav.Link>Home</Nav.Link>
								</LinkContainer>
								<LinkContainer to="/memes">
									<Nav.Link>Memes</Nav.Link>
								</LinkContainer>
								{/* <LinkContainer to='/contact'>
                  <Nav.Link>Contact</Nav.Link>
                </LinkContainer> */}

								{userAddress ? (
									<LinkContainer to="/dashboard">
										<Nav.Link>Dashboard</Nav.Link>
									</LinkContainer>
								) : (
									<Nav.Link onClick={() => setOpen(true)}>Dashboard</Nav.Link>
								)}
							</div>
							<div className="navbar__right">
								{/* {themeMode === 'light' ? (
                  <MdDarkMode
                    className='moon_icon'
                    onClick={setDarkAndLightMode}
                  />
                ) : (
                  <MdOutlineLightMode
                    className='sun_icon'
                    onClick={setDarkAndLightMode}
                  />
                )} */}

								{!userAddress ? (
									<button className="btn_brand" onClick={() => setOpen(true)}>
										{/* <button className='btn_brand btn_brand_disabled'> */}
										<BsWallet2 />
										Connect Wallet
									</button>
								) : (
									<button
										className="btn_brand user_id"
										onClick={() => setOpenDisconnectModal(true)}
									>
										<Image
											src={walletType === 'MetaMask' ? MetaMask : ''}
											alt=""
										/>
										<a>{`${userAddress.substring(
											0,
											5
										)}...${userAddress.substring(userAddress.length - 6)}`}</a>
									</button>
								)}
							</div>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
			<WalletsPopup show={open} handleClose={() => setOpen(false)} />
			<DisConnect
				show={openDisconnectModal}
				handleClose={() => setOpenDisconnectModal(false)}
			/>
		</>
	)
}

export default Header
