import { useCallback, useEffect, useState } from 'react'
import { Image } from 'react-bootstrap'
import { FaRegCommentDots } from 'react-icons/fa'
import { FiTrash } from 'react-icons/fi'
import { AiOutlineLike, AiFillLike, AiOutlineShareAlt } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { numberFormateWithOutDecimals } from '../../../Utilities/Util'
import ShareModal from '../../Modals/ShareModal/ShareModal'
import { useHistory } from 'react-router-dom'
import Lightbox from 'react-awesome-lightbox'
import 'react-awesome-lightbox/build/style.css'

import WalletsPopup from '../../Modals/WalletsPopup/WalletsPopup'
import WarningModal from '../../Modals/WarningModal/WarningModal'
import LogoOne from '../../../Assets/lol.png'
import USer from '../../../Assets/Profile_icon.svg'
import DeleteConfirm from '../../Modals/WalletsPopup/DeleteConfirm'
import Meta from '../../../Pages/MemeByID/Meta'
import {
	collection,
	deleteDoc,
	doc,
	serverTimestamp,
	setDoc,
	updateDoc,
} from 'firebase/firestore'
import db from '../../../firebase'
import { AppCollections } from '../../../Utilities/constants'

const FeedCard = ({ data, id }) => {
	let history = useHistory()
	// redux state
	const [open, setOpen] = useState(false)
	const [warningLoading, setWarningLoading] = useState(false)
	const [shareOpen, setShareOpen] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)

	const { isAvalToLike, userAddress, profileLoading } = useSelector(
		(state) => state.profile
	)
	const [userLiked, setUserLiked] = useState(false)
	const [likeCount, setLikeCount] = useState(0)

	const [isViewerOpen, setIsViewerOpen] = useState(false)

	useEffect(() => {
		setUserLiked(data?.likedUsers?.findIndex((id) => id === userAddress) !== -1)
		setLikeCount(data?.likes)
	}, [data?.likedUsers, data?.likes, userAddress])

	const likeTheMemePost = async () => {
		if (!isAvalToLike) {
			setWarningLoading(true)
			return
		}
		const docRef = doc(
			db,
			`${AppCollections.MEMES}/${id}/${AppCollections.LIKES}/${userAddress}`
		)
		try {
			if (userLiked) {
				setUserLiked(false)
				setLikeCount(likeCount - 1)
				await deleteDoc(docRef)
				return
			}
			setUserLiked(true)
			setLikeCount(likeCount + 1)
			await setDoc(docRef, {
				created_at: serverTimestamp(),
			})
		} catch (error) {
			console.error(error)
		}
	}

	const leaveComment = async () => {
		history.push(`/meme/${id}`)
	}

	// wallet popup
	useEffect(() => {
		if (userAddress) {
			setOpen(false)
		}
	}, [userAddress])

	// Delete Item'
	const deleteMeme = async () => {
		if (!userAddress) {
			setOpen(true)
		} else {
			await updateDoc(doc(db, AppCollections.MEMES, id), {
				archived: true,
			})
			history.push('/memes')
		}
	}

	return (
		<>
			<Meta meme={data} />
			<div className="feed_card" key={id}>
				<div className="img_gol">
					<Image src={LogoOne} alt="" />
				</div>
				<div className="user_info_section">
					<div className="user_info_section_left">
						<div className="user_image">
							<Image src={USer} alt="" />
						</div>
						<p className="user_id">{`${data?.username?.substring(
							0,
							5
						)}...${data?.username?.substring(data?.username?.length - 6)}`}</p>
					</div>
				</div>
				<h5>{data?.description}</h5>
				<div className="feed_card_image" onClick={() => setIsViewerOpen(true)}>
					<Image src={data?.image} alt="" />
				</div>
				<div className="feed_card_actions">
					{profileLoading ? null : (
						<>
							{/* <div className='feed_card_actions_left'> */}
							<div
								className="icon_wrapper"
								onClick={() => likeTheMemePost('like')}
							>
								{userLiked ? (
									<AiFillLike className="brand" />
								) : (
									<AiOutlineLike />
								)}
								<span>{likeCount}</span>
							</div>

							<div className="icon_wrapper" onClick={leaveComment}>
								<FaRegCommentDots />
								<span>{data?.comments}</span>
							</div>
							{/* </div> */}
							{/* <div className='feed_card_actions_right'> */}
							{data?.username === userAddress && (
								<div
									className="icon_wrapper"
									onClick={() => setDeleteModal(true)}
								>
									<FiTrash />
								</div>
							)}
							<div className="icon_wrapper" onClick={() => setShareOpen(true)}>
								<AiOutlineShareAlt />
							</div>
							{/* </div> */}
						</>
					)}
				</div>
			</div>
			{isViewerOpen && data?.image && data?.description && (
				<Lightbox
					onClose={() => setIsViewerOpen(false)}
					image={data?.image}
					title={data?.description}
				></Lightbox>
			)}
			<WarningModal
				show={warningLoading}
				handleClose={() => setWarningLoading(false)}
				warningType="like"
			/>
			<WalletsPopup show={open} handleClose={() => setOpen(false)} />
			<ShareModal
				memID={id}
				show={shareOpen}
				handleClose={() => setShareOpen(false)}
			/>
			<DeleteConfirm
				show={deleteModal}
				deleteMeme={deleteMeme}
				handleClose={() => setDeleteModal(false)}
			/>
		</>
	)
}

export default FeedCard
