import {useState} from 'react'
import {Collapse} from 'react-bootstrap'
import {BiChevronDown, BiChevronUp} from 'react-icons/bi'

interface FaqItemProps {
  question: string
  content: string
  id: number
}

const FaqItem = ({question, content, id}: FaqItemProps) => {
  const [open, setOpen] = useState(false)
  return (
    <div className='faq_containet'>
      <div
        className='faq_item'
        onClick={() => setOpen(!open)}
        aria-controls='example-collapse-text'
        aria-expanded={open}
      >
        <div>
          <h6>{question}</h6>
        </div>
        <div>{open ? <BiChevronUp /> : <BiChevronDown />}</div>
      </div>
      <Collapse in={open}>
        <div id='example-collapse-text'>
          {id === 2 ? (
            <a href={content} target='_blank' className='mt-3'>
              Contarct Address
            </a>
          ) : (
            <p className='mt-3'>{content}</p>
          )}
        </div>
      </Collapse>
    </div>
  )
}

export default FaqItem
