import React, {useEffect, useMemo} from 'react'
import {Table} from 'react-bootstrap'
import {useTable, useGlobalFilter, usePagination} from 'react-table'
import {
  CgPushChevronLeft,
  CgChevronLeft,
  CgChevronRight,
  CgPushChevronRight,
} from 'react-icons/cg'
import {useSelector} from 'react-redux'

const RewardsTable = ({info}: any) => {
  const {rewardsHistory} = useSelector((state: any) => state.root)
  // const MockData = [
  //   {
  //     transID: '0x4e59664s5df5cs65542s1bshdawd654478qes',
  //     type: 'Add Liquidity',
  //     amount: '648 USDC',
  //     date: '10-06-2021 | 08:45:12',
  //   },
  //   {
  //     transID: '0x4e59664s5df5cs65542s1bshdawd654478qes',
  //     type: 'Remove Liquidity',
  //     liquidityAmount: '648 USDC',
  //     date: '10-06-2021 | 08:45:12',
  //   },
  //   {
  //     transID: '0x4e59664s5df5cs65542s1bshdawd654478qes',
  //     type: 'Add Liquidity',
  //     liquidityAmount: '648 USDC',
  //     date: '10-06-2021 | 08:45:12',
  //   },
  //   {
  //     transID: '0x4e59664s5df5cs65542s1bshdawd654478qes',
  //     type: 'Remove Liquidity',
  //     liquidityAmount: '648 USDC',
  //     date: '10-06-2021 | 08:45:12',
  //   },
  //   {
  //     transID: '0x4e59664s5df5cs65542s1bshdawd654478qes',
  //     type: 'Add Liquidity',
  //     liquidityAmount: '648 USDC',
  //     date: '10-06-2021 | 08:45:12',
  //   },
  // ]
  //   const MockData: any = [
  //     {
  //       id: '0x4e59664s5df5cs65542s1bshdawd654478qes',
  //       action: 'Add Liquidity',
  //       liquidityAmount: '648 USDC',
  //       date: '10-06-2021 | 08:45:12',
  //     },
  //     {
  //       id: '0x4e59664s5df5cs65542s1bshdawd654478qes',
  //       action: 'Remove Liquidity',
  //       liquidityAmount: '648 USDC',
  //       date: '10-06-2021 | 08:45:12',
  //     },
  //     {
  //       id: '0x4e59664s5df5cs65542s1bshdawd654478qes',
  //       action: 'Add Liquidity',
  //       liquidityAmount: '648 USDC',
  //       date: '10-06-2021 | 08:45:12',
  //     },
  //     {
  //       id: '0x4e59664s5df5cs65542s1bshdawd654478qes',
  //       action: 'Remove Liquidity',
  //       liquidityAmount: '648 USDC',
  //       date: '10-06-2021 | 08:45:12',
  //     },
  //     {
  //       id: '0x4e59664s5df5cs65542s1bshdawd654478qes',
  //       action: 'Add Liquidity',
  //       liquidityAmount: '648 USDC',
  //       date: '10-06-2021 | 08:45:12',
  //     },
  //     {
  //       id: '0x4e59664s5df5cs65542s1bshdawd654478qes',
  //       action: 'ac Liquidity',
  //       liquidityAmount: '648 USDC',
  //       date: '10-06-2021 | 09:45:12',
  //     },
  //     {
  //       id: 'efe',
  //       action: 'Adacd Liquidity',
  //       liquidityAmount: '648 USDC',
  //       date: '10-06-2021 | 08:45:12',
  //     },
  //     {
  //       id: 'wefwe78qes',
  //       action: 'Remove Liquidity',
  //       liquidityAmount: '648 USDC',
  //       date: '10-06-2021 | 08:45:12',
  //     },
  //     {
  //       id: '0x4e59664s5df5cs65542s1bshdawd654478qes',
  //       action: 'Add Liquiefedity',
  //       liquidityAmount: '648 USDC',
  //       date: '10-06-2021 | 08:45:12',
  //     },
  //     {
  //       id: 'wefwefewfwe',
  //       action: 'Remove Liquidity',
  //       liquidityAmount: '648 USDC',
  //       date: '10-06-2021 | 08:45:12',
  //     },
  //     {
  //       id: 'wfewfew',
  //       action: 'Add Liquidity',
  //       liquidityAmount: '648 USDC',
  //       date: '10-06-2021 | 08:45:12',
  //     },
  //     {
  //       id: '0x4e59664s5df5cs65542s1bshdawd654478qes',
  //       action: 'Remove Liquidity',
  //       liquidityAmount: '648 USDC',
  //       date: '10-06-2021 | 08:45:12',
  //     },
  //     {
  //       id: '0x4e5966xc cx 4s5df5cs65542s1bshdawd654478qes',
  //       action: 'Add Liquidity',
  //       liquidityAmount: '648 USDC',
  //       date: '10-06-2021 | 08:45:12',
  //     },
  //     {
  //       id: '0x4e59664s5df5cs65542s1bshdawd654478qes',
  //       action: 'Remove Liquidity',
  //       liquidityAmount: '648 USDC',
  //       date: '10-06-2021 | 08:45:12',
  //     },
  //   ]

  const COLUMNS: any = [
    {
      Header: 'Transaction Id',
      accessor: 'transID',
      Cell: (rewardsHash: any) => (
        <a
          target='_blank'
          href={`https://testnet.bscscan.com/tx/${rewardsHash.value}`}
        >
          {rewardsHash.value}
        </a>
      ),
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'Rewards',
      accessor: 'amount',
      Cell: (amount: any) => {
        return `${amount.value} GOL`
      },
    },
    {
      Header: 'Date',
      accessor: 'date',
    },
  ]
  const columns = useMemo(() => COLUMNS, [])
  // const data = useMemo(() => MockData, [])
  const data = useMemo(() => [...info], [rewardsHistory])

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    setPageSize,
    pageCount,
  }: any = tableInstance
  const {globalFilter, pageIndex, pageSize}: any = state

  const handlePageClick = (data: any) => {
    gotoPage(data.selected)
  }

  return (
    <div>
      {/* <div className='global__filter'>
        <GlobalFilter
          filter={globalFilter}
          setFilter={setGlobalFilter}
          options={options}
          label='Action'
        />
      </div> */}
      <h5 className='vcs'>Rewards History</h5>
      <Table responsive={'lg'} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: any) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
      <div className='table__bottom'>
        <div className='table__bottom__left'>
          {/* <p className='showing'>
            <br />
            Showing{' '}
            <span className='txt__black'>
              {pageIndex * pageSize + 1}
            </span> to{' '}
            <span className='txt__black'>{pageSize * (pageIndex + 1)}</span> of{' '}
            <span className='txt__black'>{info.length}</span> elements
          </p> */}
        </div>
        <div className='table__bottom__right'>
          <button
            className={!canPreviousPage ? 'btn__disabled' : ''}
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <CgPushChevronLeft />
          </button>
          <button
            className={!canPreviousPage ? 'btn__disabled' : ''}
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <CgChevronLeft />
          </button>
          <button
            className={!canNextPage ? 'btn__disabled' : ''}
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <CgChevronRight />
          </button>
          <button
            className={!canNextPage ? 'btn__disabled' : ''}
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <CgPushChevronRight />
          </button>
        </div>
      </div>
    </div>
  )
}

export default RewardsTable
