import {combineReducers} from 'redux'
import {profileReducer} from './Profile/reducers'
import {mainReducer} from './Root/reducers'

const rootReducer = combineReducers({
  root: mainReducer,
  profile: profileReducer,
})

export default rootReducer
