import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'

// Pages
import LandingPage from './Pages/LandingPage'
import NotFound from './Pages/NotFound/NotFound'
import MemeByID from './Pages/MemeByID/MemeByID'
import Feed from './Pages/Feed'
import Dashboard from './Pages/Dashboard'

// components
import Header from './Components/Header/Header'
import Footer from './Components/Footer/Footer'
import ArrowUp from './Components/ArrowUp/ArrowUp'
import UserMemes from './Pages/UserMemes'
import  { useEffect,  } from 'react'
import { getAuth, getIdToken, onAuthStateChanged } from 'firebase/auth'
import {  useDispatch } from 'react-redux'
import { getUserData } from './Redux/Root/actions'
import axios from 'axios'
import api from './api'

export default function App() {

  const dispatch = useDispatch();
 
  const onAuthChange = async (user) => {
    if(!user)return
    console.log('onAuthChange')
    const token = await getIdToken(user);
    console.log('setting token', token)
    api.defaults.headers['Authorization'] = `bearer ${token}` as any;
    dispatch(getUserData())
   
  }


  useEffect(()=> {
    const auth = getAuth()
    if(auth.currentUser){
      console.log('logging out')
      auth.signOut()
    }
    return  onAuthStateChanged(auth, onAuthChange)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

    return (
      <div className='app'>
        <Router>
          <Header />
          <Switch>
            <Route path='/' exact component={LandingPage} />
            <Route path='/memes' component={Feed} />
            <Route path='/userMemes' component={UserMemes} />
            <Route path='/meme/:id' component={MemeByID} />
            {/* <Route path='/contact' exact component={Contact} /> */}
            <Route path='/dashboard' exact component={Dashboard} />
            <Route path='*' component={NotFound} />
          </Switch>
          <ArrowUp />
          <Footer />
        </Router>
      </div>
    )

  
}
