import {Image} from 'react-bootstrap'
// image
import Close from '../../../Assets/Close.svg'
import DefaultModal from '../DefaultModal/DefaultModal'
const DeleteConfirm = ({deleteMeme, show, handleClose}: any) => {
  return (
    <DefaultModal show={show} handleClose={handleClose} type='fail'>
      <div className='success__body'>
        <Image src={Close} alt='' className='mb-3 loader' />
        <h3 className='got_txt'>Are You Sure ?</h3>
        <h6 className='mb-3'>Do you really want to delete this meme?</h6>
        <div className='d-flex align-items-center justify-content-evenly mt-5'>
          <button
            className='btn_brand btn_outlined'
            onClick={() => handleClose()}
          >
            Cancel
          </button>
          <button onClick={() => deleteMeme()} className='btn_brand'>
            Done
          </button>
        </div>
      </div>
    </DefaultModal>
  )
}

export default DeleteConfirm
