import {Col, Container, Image, Row} from 'react-bootstrap'
import {Link} from 'react-router-dom'
// Svgs
import LogoLeft from '../../../Assets/LandingPage/abt_left.png'
import LogoRight from '../../../Assets/LandingPage/abt_right.png'
import LogoOne from '../../../Assets/lol.png'

const GolToken = () => {
  return (
    <section className='gol_token'>
      <Image src={LogoLeft} alt='' className='abt_left' />
      <Image src={LogoRight} alt='' className='abt_right' />
      <Container>
        <div className='section_info'>
          {/* <p className='section_small_heading'>About</p> */}
          <h1 className='section_heading'>
            <span>Buy</span> <span className='gols_text'>$</span>GOL on Pancake
            Swap
          </h1>
        </div>
        <Row>
          <Col xs={12} sm={12} md={12} lg={6} xl={6} className='m-auto'>
            <div className='gol_token_right'>
              <div className='gol_img'>
                <Image src={LogoOne} alt='' />
              </div>
              <p className='sdfcsfsd'>
                Game of Laughs is{' '}
                <span style={{fontWeight: 'bold', color: '#ffffff'}}>
                  a Meme To Earn
                </span>{' '}
                platform where meme creators and the users are rewarded for
                creating content and using the platform.
              </p>
              <p className='txt_brand'>
                $GOL is the native currency for the Game of Laughs platform and
                rewards will be paid in GOL tokens.
              </p>
              <a
                target='_blank'
                href='https://pancakeswap.finance/swap?outputCurrency=0x21702036f0a6076C56421B04Ca41F4fF5EC99344'
                rel='noopener noreferrer'
              >
                <button className='btn_brand'>Buy $GOL on Pancake</button>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default GolToken
