import { Container} from 'react-bootstrap'
import {useSelector} from 'react-redux'
import DashboardBanner from './DashboardBanner'
import MyPortfolio from './MyPortfolio'
import RewardsTable from './RewardsTable'

const DashboardWrapper = () => {
  const {rewardsHistory} = useSelector((state: any) => state.root)
  return (
    <>
      <DashboardBanner />
      <div className='dashboard'>
        <Container fluid={'lg'}>
          <MyPortfolio />
          <div className='liquidity__table'>
            {rewardsHistory?.length > 0 && (
              <RewardsTable info={rewardsHistory} />
            )}
            {/* <div className='portfolio__container'> */}
            {/* <Row>
              <Col></Col>
            </Row> */}
            {/* </div> */}
          </div>
        </Container>
      </div>
    </>
  )
}

export default DashboardWrapper
