import {Col, Container, Image, Row} from 'react-bootstrap'
import Logo from '../../../Assets/lol.png'
import Token from '../../../Assets/chart.svg'

const Tokenomics = () => {
  return (
    <section className='whitePaper'>
      <Container>
        <div className='whitePaper_container'>
          <div className='section_info'>
            {/* <p className='section_small_heading'>Tokenomics</p> */}
            <h1 className='section_heading'>
              <span>Check out</span> TOKENOMICS OF GOL ECONOMY
            </h1>
          </div>

          <div className='whitePaper_info'>
            <Row>
              <Col xs={12} sm={12} md={12} lg={6} xl={6} className='mb-3'>
                <div className='whitePaper_info_left'>
                  <div className='chart_section'>
                    <Image src={Token} alt='' />
                  </div>
                  <div className='info_section'>
                    <div className='content'>
                      <div
                        className='color'
                        style={{backgroundColor: '#5289C9'}}
                      ></div>
                      <div className='text'>3,500,000,000 (35%)</div>
                    </div>
                    <div className='content'>
                      <div
                        className='color'
                        style={{backgroundColor: '#2862FF'}}
                      ></div>
                      <div className='text'>1,000,000,000 (10%)</div>
                    </div>
                    <div className='content'>
                      <div
                        className='color'
                        style={{backgroundColor: '#FF6A81'}}
                      ></div>
                      <div className='text'>1,500,000,000 (15%)</div>
                    </div>
                    <div className='content'>
                      <div
                        className='color'
                        style={{backgroundColor: '#EB4A8A'}}
                      ></div>
                      <div className='text'>4,000,000,000 (40%)</div>
                    </div>
                  </div>
                  {/* <p>
                    Why have we chosen to donate to neuroscience & artificial
                    intelligence charities? Because the sky is the limit when it
                    comes to these evolving technologies.{' '}
                  </p>
                  <p>
                    These programs will directly be responsible for the
                    advancement of mankind.
                  </p>
                  <p>
                    These technologies are making breakthroughs in health, space
                    exploration, and improving the quality of life. We share
                    very similar views with Elon Musk when it comes to how, and
                    why mankind should push to further explore these
                    technologies.
                  </p>
                  <p> Plus they are just cool!!!</p> */}
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6} className='mb-3'>
                <div className='whitePaper_info_right'>
                  <p className='question'>
                    <img src={Logo} alt='' />
                    35% for Meme to earn rewards that will be unlocked in phases
                  </p>
                  <p className='question two'>
                    <img src={Logo} alt='' />
                    10% for team
                  </p>
                  <p className='question three'>
                    <img src={Logo} alt='' />
                    15% for marketing
                  </p>
                  <p className='question three'>
                    <img src={Logo} alt='' />
                    40% IDO + liquidity
                  </p>
                  <p>Each transaction will have 4% tax</p>
                  <p>2% tax goes to liquidity</p>
                  <p>2% tax goes to marketing</p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default Tokenomics
