import {Container, Row, Col, Image} from 'react-bootstrap'
import {BiWalletAlt} from 'react-icons/bi'
import {FaExchangeAlt} from 'react-icons/fa'
import {MdOutlineSell} from 'react-icons/md'
import {v4 as uuid} from 'uuid'
import ImgOne from '../../../Assets/LandingPage/lanister.png'
import ImgTwo from '../../../Assets/LandingPage/targaryen.png'
import ImgThree from '../../../Assets/LandingPage/stark.png'

import One from '../../../Assets/LandingPage/wallet.svg'
import Two from '../../../Assets/LandingPage/exchange.svg'
import Three from '../../../Assets/LandingPage/processing.svg'

const HowItWorks = () => {
  const flow = [
    {
      id: uuid(),
      title: 'Set up your wallet',
      info: 'Once you’ve set up your wallet of Metamask, connect it to Game of laughs by clicking the wallet icon in the top right corner',
      icon: ImgOne,
      img: One,
    },
    {
      id: uuid(),
      title: 'Purchase BNB on an Exchange',
      info: `Once you’ve done of Purchase BNB on an Exchange Then, withdraw the BNB over to  Metamask`,
      icon: ImgTwo,
      img: Two,
    },
    {
      id: uuid(),
      title: 'Continue on PancakeSwap',
      info: 'https://pancakeswap.finance/swap?outputCurrency=0x21702036f0a6076C56421B04Ca41F4fF5EC99344',
      icon: ImgThree,
      img: Three,
    },
  ]
  return (
    <div className='how_it_works'>
      <div className='section_info'>
        {/* <p className='section_small_heading'>How it works</p> */}
        <h1 className='section_heading'>
          <span>How to</span> Buy GOL Tokens
        </h1>
      </div>
      <Container>
        <Row className='mt-5 rr'>
          {flow?.map((item, i) => (
            <Col xs={12} sm={12} md={12} lg={4} xl={4} key={item.id}>
              <div className='hw_card'>
                <div className='icon_bg'>
                  <Image src={item.icon} alt='' />
                </div>
                <h5>
                  <Image src={item.img} alt='' />
                  {item.title}
                </h5>
                {i === 2 ? (
                  <a className='eeee' href={item.info} target='_blank'>
                    Click here to continue to Pancake swap
                  </a>
                ) : (
                  <p>{item.info}</p>
                )}
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default HowItWorks
