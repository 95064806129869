import Banner from '../Components/LandingPage/Banner/Banner'
import Faq from '../Components/LandingPage/Faqs/Faq'
import GolToken from '../Components/LandingPage/GolToken/GolToken'
import HowItWorks from '../Components/LandingPage/HowItWorks/HowItWorks'
import NewsLetter from '../Components/LandingPage/NewsLetter/NewsLetter'
import RoadMap from '../Components/LandingPage/RoadMap/RoadMap'
import Tokenomics from '../Components/LandingPage/Tokenomics/Tokenomics'

const LandingPage = () => {
  return (
    <div>
      <Banner />
      <GolToken />
      <HowItWorks />
      <Tokenomics />
      <RoadMap />
      <Faq />
      <NewsLetter />
    </div>
  )
}

export default LandingPage
