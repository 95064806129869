import {Image} from 'react-bootstrap'

const CommentsModal = () => {
  return (
    <div>
      <h3>Oops!!</h3>
      <h6 className='mb-3'>You can only comment 100 times a day.</h6>
    </div>
  )
}

export default CommentsModal
