import {useEffect, useState} from 'react'
import {Image, Modal} from 'react-bootstrap'
import {CgClose} from 'react-icons/cg'
import MetaMaskNotFound from '../MetaMaskNotFound/MetaMaskNotFound'
import {WalletsPopupProps} from './WalletsPopup.d'

// image
import MetaMaskFox from '../../../Assets/MetaMask.svg'

// redux imports
import {useDispatch} from 'react-redux'
import {connToMetaMask} from '../../../Redux/Profile/actions'

// providers
import {metaMaskProvider} from '../../../Redux/Blockchain/contracts'


const WalletsPopup = ({show, handleClose}: WalletsPopupProps) => {
  const [meatMaskShow, setMeatMaskShow] = useState(false)
  const dispatch = useDispatch()

  const connectToMetaMask = async () => {


    if (metaMaskProvider?.isMetaMask) {
      dispatch(connToMetaMask())   
      

    } else {
      openMetaMaskModal()
    }
  }


  //  MetaMAsk notfound PopUP
  const closeMetaMaskModal = () => {
    setMeatMaskShow(false)
  }
  const openMetaMaskModal = () => {
    setMeatMaskShow(true)
    handleClose()
  }

  const onAccountsChanged = () => {
    dispatch(connToMetaMask())
    
  }

  const onChainChanged = () => {
    window.location.reload()
  }

  // User Account Changed

  useEffect(() => {
    if (metaMaskProvider) {
    metaMaskProvider.on('accountsChanged', onAccountsChanged)

    return () => {
      metaMaskProvider.removeListener('accountsChanged', onAccountsChanged)
    }
    }
  }, [])
  

  // USer Account Changed

  useEffect(() => {
    if (metaMaskProvider) {
      metaMaskProvider.on('chainChanged', onChainChanged)
      return () => {
        metaMaskProvider.removeListener('chainChanged', onChainChanged)
      }
    }
  }, [])

  // useEffect(() => {
  //   const checkIt = async () => {
  //     if (typeof window.ethereum !== 'undefined') {
  //       // dispatch(connToMetaMask())
  //       const accounts = await web3.eth.getAccounts()
  //       if (accounts[0]) {
  //         dispatch(connToMetaMask())
  //       }
  //     }
  //   }
  //   checkIt()
  // }, [])

  return (
    <>
      <Modal
        className='buy__token__modal successModal wallets'
        show={show}
        onHide={handleClose}
      >
        <div className='buy__cpt__modal'>
          <div className='buy__cpt__header'>
            <div className='buy__cpt__header__tile'>
              <h4>Connect To a Wallet</h4>
            </div>
            <div className='buy__cpt__header__close' onClick={handleClose}>
              <CgClose />
            </div>
          </div>
          <div className='success__body'>
            <div className='wallet' onClick={connectToMetaMask}>
              <h5>MetaMask</h5>
              <Image src={MetaMaskFox} alt='' />
            </div>
          </div>
        </div>
      </Modal>
      <MetaMaskNotFound show={meatMaskShow} handleClose={closeMetaMaskModal} />
    </>
  )
}

export default WalletsPopup
