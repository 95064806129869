import {Container, Row, Col, Image} from 'react-bootstrap'
import FaqItem from './FaqItem'
import {v4 as uuid} from 'uuid'

import NightKingImage from '../../../Assets/LandingPage/Night_King.png'

const Faq = () => {
  const faqItems = [
    {
      id: uuid(),
      question: 'What does Game of Laughs do?',
      answer:
        'Game of Laughs is a Meme to Earn Platform where meme creators submit memes and get paid with the GOL tokens based on the post engagement. Even those who don’t create memes will earn GOL tokens for interacting on the platform.',
    },
    {
      id: uuid(),
      question: 'Where can I buy GOL tokens?',
      answer: `You can buy GOL tokens on Pancake Swap`,
    },
    {
      id: uuid(),
      question: 'What’s the GOL contract address?',
      answer: `https://pancakeswap.finance/swap?outputCurrency=0x21702036f0a6076C56421B04Ca41F4fF5EC99344`,
    },
  ]
  return (
    <div className='faq'>
      <div className='main'>
        <div className='section_info'>
          <h1 className='section_heading'>
            <span>Frequently asked</span> GOL questions
          </h1>
        </div>
        <div className='img_sd'>
          <Image src={NightKingImage} alt='' />
        </div>
      </div>
      <div className='faq__containerrrrr'>
        <Container>
          <div className='faq__container'>
            <Row>
              <Col xs={12} sm={12} md={12} lg={6} xl={6} className='mb-3 frrfr'>
                <div className='img_sd'>
                  <Image src={NightKingImage} alt='' />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6} className='mb-3'>
                <div className='lol'>
                  <div className='section_info rere'>
                    {/* <p className='section_small_heading'>have a question</p> */}
                    <h1 className='section_heading'>
                      <span>Frequently asked</span> GOL questions
                    </h1>
                  </div>
                  <div className='maz'>
                    {faqItems?.map((item, i) => (
                      // <Col xl={12} key={item.id}>
                      <FaqItem
                        question={item.question}
                        content={item.answer}
                        key={item.id}
                        id={i}
                      />
                      // </Col>
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Faq
