import { useEffect, useState } from 'react'
import { Image } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import FeedCard from '../../Components/FeedContent/FeedCard/FeedCard'
import FeedCardLoading from '../../Components/FeedContent/FeedCard/FeedCardLoading'
import WalletsPopup from '../../Components/Modals/WalletsPopup/WalletsPopup'
import WarningModal from '../../Components/Modals/WarningModal/WarningModal'
import db from '../../firebase'
import { IoMdPaperPlane } from 'react-icons/io'
import { FiTrash } from 'react-icons/fi'
import { v4 as uuid } from 'uuid'
import format from 'date-fns/format'
import DefaultModal from '../../Components/Modals/DefaultModal/DefaultModal'
import CommentsModal from '../../Components/Modals/WalletsPopup/CommentsModal'
import USer from '../../Assets/Profile_icon.svg'
import Meta from './Meta'
import {
	collection,
	deleteDoc,
	doc,
	getDoc,
	onSnapshot,
	orderBy,
	query,
	serverTimestamp,
	setDoc,
} from 'firebase/firestore'
import { AppCollections } from '../../Utilities/constants'
import dayjs from 'dayjs'

const MemeByID = () => {
	const [postSuccess, setPostSuccess] = useState<boolean>(false)

	const { isAvalToLike, userAddress, profileLoading } = useSelector(
		(state: any) => state.profile
	)
	const {
		commentsCount: cCount,
		dailyComments,
		todaysDate,
	} = useSelector((state: any) => state.root)

	const { id }: any = useParams()
	let history = useHistory()
	const [meme, setMeme] = useState<any>({})
	const [loading, setLoading] = useState(true)
	const [open, setOpen] = useState(false)
	const [warningLoading, setWarningLoading] = useState<boolean>(false)
	const [comment, setComment] = useState<any>('')
	const [commentLoading, setCommentLoading] = useState(false)
	const [comments, setComments] = useState<any>([])

	useEffect(() => {
		if (id) {
			const d = doc(db, AppCollections.MEMES, id)
			return onSnapshot(d, (snap) => {
				setLoading(false)
				if (snap.exists()) {
					setMeme(snap.data())
					return
				}
				history.push('/notfound')
			})
		}
	}, [id])

	useEffect(() => {
		return setCommentSubscription()
	}, [])

	const setCommentSubscription = () => {
		const q = query(
			collection(db, `memes/${id}/comments`),
			orderBy('created_at', 'desc')
		)
		return onSnapshot(q, (snap) => {
			console.log('onSnapComment')
			if (snap.empty) {
				setComments([])
				return
			}
			setComments(snap.docs.map((d) => d.data()))
		})
	}
	const deleteComment = async (commentId: string) => {
		console.log(commentId)
		if (!userAddress) {
			setOpen(true)
		} else {
			await deleteDoc(doc(db, `memes/${id}/comments/${commentId}`))
		}
	}

	const submitComment = async (e: any) => {
		setComment('')
		if (!userAddress) return

		setCommentLoading(true)
		e.preventDefault()
		const newComment = {
			commentID: uuid(),
			userName: userAddress,
			comment,
			created_at: serverTimestamp(),
		}
		if (dailyComments < 100) {
			await setDoc(
				doc(
					db,
					`${AppCollections.MEMES}/${id}/${AppCollections.COMMENTS}/${newComment.commentID}`
				),
				newComment
			)
			setCommentLoading(false)
		} else {
			setCommentLoading(false)
			setPostSuccess(true)
		}
	}
	const createComment = () => {
		if (!userAddress) {
			setOpen(true)
		} else if (userAddress && isAvalToLike) {
			//TODO: add availToLike later
		} else {
			setWarningLoading(true)
		}
	}
	// wallet popup
	useEffect(() => {
		if (userAddress) {
			setOpen(false)
		}
	}, [userAddress])

	return (
		<>
			<Meta meme={meme} />
			<section className="feedWrapper single_meme">
				<div className="feedWrapper__container">
					{!loading && meme && <FeedCard data={meme} id={id} key={id} />}
					{loading && <FeedCardLoading count={1} />}
					{!loading && !profileLoading && (
						<>
							<form
								className="create_comment"
								onSubmit={(e) => submitComment(e)}
							>
								<input
									type="text"
									className={
										commentLoading
											? 'shadow-none form-control comment_loading'
											: 'shadow-none form-control'
									}
									placeholder="Add a comment"
									value={comment}
									onClick={createComment}
									onChange={(e) => setComment(e.target.value)}
									disabled={commentLoading}
								/>

								<button
									disabled={commentLoading || comment?.length <= 0}
									className={
										commentLoading || comment?.length <= 0
											? 'send_info_btn btn_brand_disabled'
											: 'send_info_btn'
									}
									type="submit"
								>
									<IoMdPaperPlane />
								</button>
							</form>
							{comments.length > 0
								? comments.map((comment: any) => (
										<div className="comment_wrapper" key={comment.id}>
											<div className="user_info_section">
												<div className="user_info_section_left">
													<div className="user_image">
														<Image src={USer} alt="" />
													</div>
													<p className="user_id">
														{`${comment?.userName?.substring(
															0,
															5
														)}...${comment?.userName?.substring(
															comment?.userName?.length - 6
														)}`}
													</p>
												</div>

												<div className="user_info_section_right">
													{comment?.userName === userAddress && (
														<div
															className="icon_wrapper"
															onClick={() => deleteComment(comment?.commentID)}
														>
															<FiTrash />
														</div>
													)}
												</div>
											</div>
											<div className="c_wra">
												<p className="comment">
													{comment?.comment?.toString()}

													{comment?.created_at ? (
														<span
															style={{
																marginTop: '4px',
																fontSize: '10px',
																display: 'block',
															}}
														>
															{dayjs(
																comment?.created_at?.seconds * 1000
															).fromNow()}
														</span>
													) : (
														''
													)}
												</p>
											</div>
										</div>
								  ))
								: null}
						</>
					)}
				</div>
			</section>
			<WarningModal
				show={warningLoading}
				handleClose={() => setWarningLoading(false)}
				warningType="like"
			/>
			<WalletsPopup show={open} handleClose={() => setOpen(false)} />
			<DefaultModal
				show={postSuccess}
				handleClose={() => {
					setPostSuccess(false)
				}}
				type="success"
			>
				<div className="success__body sdsfsdc">
					<CommentsModal />
				</div>
			</DefaultModal>
		</>
	)
}

export default MemeByID
