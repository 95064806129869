import {useState} from 'react'
import {useSelector} from 'react-redux'
import CreatePost from '../../Modals/CreatePost/CreatePost'

const FeedBanner = () => {
  const {userAddress} = useSelector((state: any) => state.profile)
  const [postShow, setPostShow] = useState(false)
  return (
    <>
      <div className='feedsBAnner'>
        <div className='create_Post'>
          <div className='section_info'>
            <h1 className='section_heading'>
              <span>What's</span> on your Mind ?
            </h1>
          </div>
          <div className='butotnss'>
            <button className='btn_brand mt-2' onClick={() => setPostShow(true)}>
              Post a Meme
            </button>
          </div>
        </div>
      </div>
      <CreatePost show={postShow} handleClose={() => setPostShow(false)} />
    </>
  )
}

export default FeedBanner
