import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import FeedCard from '../Components/FeedContent/FeedCard/FeedCard'
import FeedCardLoading from '../Components/FeedContent/FeedCard/FeedCardLoading'
import db from '../firebase'

const UserMemes = () => {
	const { userAddress } = useSelector((state: any) => state.profile)
	const { userPosts } = useSelector((state: any) => state.root)

	useEffect(() => {
		console.log(userPosts)
	}, [userPosts])

	if (!userAddress) {
		return <Redirect to="/" />
	}

	//   const [loading, setLoading] = useState(true)

	return (
		<>
			<section
				className="feedWrapper"
				style={{ marginTop: '66px', paddingTop: '30px' }}
			>
				<div className="feedWrapper__container">
					{/* <div className='create_Post'>
            <h5>Create Meme</h5>
            <div className='input_wrapper' onClick={() => setPostShow(true)}>
              <h6>What's on your mind?</h6>
            </div>
          </div> */}
					{userPosts?.map((meme: any) => (
						<FeedCard data={meme} id={meme?.id} key={meme?.id} />
					))}
					{/* {loading && <FeedCardLoading count={5} />} */}
				</div>
			</section>
		</>
	)
}

export default UserMemes
