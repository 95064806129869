import {CgClose} from 'react-icons/cg'
import {Image, Modal} from 'react-bootstrap'
import {FiCopy} from 'react-icons/fi'
import useClipboard from 'react-use-clipboard'
// image
import Tick from '../../../Assets/Tick.svg'
import Close from '../../../Assets/Close.svg'

// redux imports
import {useSelector} from 'react-redux'

const RewardsSuccess = () => {
  const {rewardsHash, rewardsError} = useSelector((state: any) => state.root)
  // clipboard
  const [isCopied, setCopied] = useClipboard(rewardsHash, {
    successDuration: 2000,
  })

  return (
    <>
      {!rewardsError && rewardsHash ? (
        <>
          <Image src={Tick} alt='' className='mb-3 loader' />
          <h3>Transaction success</h3>
          <h6 className='mb-3'>You have been successfully Claimed rewards</h6>

          <div className='user__id'>
            <p onClick={setCopied} className='txt__gray id'>
              {rewardsHash}
              <span>
                <FiCopy />
              </span>
            </p>
            <div className='toolt'>{isCopied ? 'Copied' : 'Copy'}</div>
          </div>
          <a
            target='_blank'
            style={{color: '#ffffff'}}
            href={`https://bscscan.com/tx/${rewardsHash}`}
            className='btn_brand'
          >
            View on BSC Scan
          </a>
        </>
      ) : (
        rewardsError && (
          <>
            <Image src={Close} alt='' className='loader' />
            <h3 className='mt-3 text-danger'>Transaction Failed</h3>
          </>
        )
      )}
    </>
  )
}

export default RewardsSuccess
