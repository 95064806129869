import {Col, Container, Image, Row} from 'react-bootstrap'
import {v4 as uuid} from 'uuid'

// svgs
import Tick from '../../../Assets/LandingPage/tick.svg'
import Logo from '../../../Assets/lol.png'
import ONE from '../../../Assets/LandingPage/House_Stark_Direwolf.png'
import TWO from '../../../Assets/LandingPage/House_Lannister_Lion.png'
import THREE from '../../../Assets/LandingPage/House_Targaryen_Dragon.png'

const RoadMap = () => {
  const roadMapInfoOne = [
    {
      id: uuid(),
      phase: 'Phase 1',
      img: TWO,
      items: [
        {id: uuid(), itemName: 'Game of Laughs platform launch'},
        {id: uuid(), itemName: 'Listing on Pancake Swap'},
        {id: uuid(), itemName: 'Listing on Coin Gecko'},
        {id: uuid(), itemName: 'Listing on Coin Market Cap'},
        {id: uuid(), itemName: 'Audit'},
        {id: uuid(), itemName: 'Meme contest + Giveaways'},
      ],
    },
    {
      id: uuid(),
      phase: 'Phase 2',
      img: ONE,
      items: [
        {
          id: uuid(),
          itemName: 'Partnerships with influencers on Youtube, Twitter, Reddit',
        },
        {id: uuid(), itemName: 'Tie-ups with Game of Thrones cast members'},
        {id: uuid(), itemName: 'Merch store launch'},
      ],
    },
    {
      id: uuid(),
      phase: 'Phase 3',
      img: THREE,
      items: [
        {id: uuid(), itemName: 'NFT game launch'},
        {id: uuid(), itemName: 'Listing on major exchanges'},
      ],
    },
  ]

  return (
    <section className='roadMap'>
      <div className='section_info'>
        {/* <p className='section_small_heading'>Road Map</p> */}
        <h1 className='section_heading'>
          <span>Our</span> Roadmap
        </h1>
      </div>
      <Container>
        <Row>
          {roadMapInfoOne.map((item) => (
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={4}
              xl={4}
              key={item.id}
              className='mb-5 roadMap_main '
            >
              <div className='roadMap_card' 
               >
                <div className='roadMap_card_img'>
                  <Image src={item.img} alt='' />
                </div>
                <p className='phase'>{item.phase}</p>
                <div className='roadMap_card_items_wrapper'>
                  {item.items.map((list) => (
                    <div className='item_wrapper' key={list.id}>
                      <Image src={Logo} alt='' />{' '}
                      <p >{list.itemName}</p>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default RoadMap
